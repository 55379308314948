(function($) {
    $(document).ready(function () {
        const toTop = $('#to-top');
        const sideNav = $('#side-nav');
        const sideNavMarker = $('#side-nav-marker');
        const header = $('#header');
        const scrollDown = $('.scrollDown');

        const burger = $('#burger');

        const menu = new MmenuLight(
            document.querySelector('#menu-mobile'),
            "(max-width: 768px)"
        );

        var navigator = menu.navigation({
            'title': ''
        });

        const drawer = menu.offcanvas({
            // options
        });

        scrollDown.fadeOut();
        window.setTimeout(function() {
            scrollDown.css('z-index', 10 );
            scrollDown.fadeIn();
        }, 2500);

        scrollDown.click(function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: $('#willkommen').offset().top - 35 }, 300);
        });

        burger.click(function () {
            drawer.open();
            burger.addClass('burger-hidden');
        });

        $('#menu-mobile').find('.close').click(function () {
            drawer.close();
            burger.removeClass('burger-hidden');
        });

        $('.mm-ocd__backdrop').click(function () {
            burger.removeClass('burger-hidden');
        });

        var btn = burger.find('.btn');

        btn.on('click', function() {
            $(this).toggleClass('active not-active');
        });

        AOS.init();

        toTop.click(function () {
            $('html, body').animate({ scrollTop: 0 }, 300);
        });

        // ---------------------------------------------------
        // ready
        // ---------------------------------------------------
        scrollFunction();

        // ---------------------------------------------------
        // scroll
        // ---------------------------------------------------
        function scrollFunction() {
            var windowScrollTop = $(window).scrollTop();

            if (windowScrollTop > 200) {
                header.addClass('scrolled');
            } else {
                header.removeClass('scrolled');
            }

            //
            // side nav
            //
            if (sideNavMarker.length > 0) {
                if (windowScrollTop > (sideNavMarker.offset().top - 130)) {
                    sideNav.addClass('fixed');
                } else {
                    sideNav.removeClass('fixed');
                }
            }

        }

        $(window).scroll(function () {
            scrollFunction();
        });




        var allPanels = $('.accordion .accordion-body').hide();
        var allAccordions = $('.accordion');

        $('.accordion .accordion-header a').click(function(e) {
            e.preventDefault();
            var thisLink = $(this);
            var openIt = true;
            if (thisLink.closest('.accordion-header').find('.read-more').hasClass('open')) {
                openIt = false;
            }

            allPanels.slideUp();
            allAccordions.find('.text-close').removeClass('inline-block');
            allAccordions.find('.text-close').addClass('hidden');

            allAccordions.find('.text-more').removeClass('hidden');
            allAccordions.find('.text-more').addClass('inline-block');
            allAccordions.find('.read-more').removeClass('open');

            if (openIt) {
                var thisAccHeader = thisLink.closest('.accordion-header');
                thisAccHeader.find('.text-more').removeClass('inline-block');
                thisAccHeader.find('.text-more').addClass('hidden');
                thisAccHeader.find('.text-close').removeClass('hidden');
                thisAccHeader.find('.read-more').addClass('open');
                thisAccHeader.next().slideDown();
            }
        });



        // ---------------------------------------------------
        // hamburger
        // ---------------------------------------------------
        const hamburger = $('#hamburger');
        hamburger.click(function (e) {
            e.preventDefault();
            hamburger.toggleClass('no-hamburger');
            $('#nav-overlay').toggleClass('in');
            header.toggleClass('nav-overlay-in');
        });


        // ---------------------------------------------------
        // execute at document ready
        // ---------------------------------------------------
        $('#nav-overlay').find('.nav-block-title.w-icon').click(function () {
            $(this).parent().toggleClass('open');
        });


        // ---------------------------------------------------
        // borlabs cookie
        // ---------------------------------------------------
        $('.js-cookie-box').click(function(e) {
            e.preventDefault();
            window.BorlabsCookie.showCookieBox();
        });



    });
}(jQuery));
